import React, { useEffect, useState } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Form, Button, InputGroup } from "react-bootstrap";

import { IServerSidePagination, IFilter, FilterOperator } from "../../grid";
import { IResponse } from "../../../interfaces/IResponse";
import {
  ICommonInputProps,
  CommonLabel,
  CustomSpinner,
  CheckHasFeedback,
  CustomInputGroupAppend
} from "../base";
import { IBaseDropdownProps, IBaseDropdownState } from "./types";
import Util from "../../util";
import "./style.scss";

const LIMIT_ITENS_PER_PAGE = 5;

class BaseDropdown extends React.Component<IBaseDropdownProps, IBaseDropdownState>
{
  static defaultProps: Partial<IBaseDropdownProps>;
  private searchInputRef = React.createRef<any>();
  private _isMounted: boolean = false;

  state = {
    debounceTimeout: 0,
    page: 1,
    pages: 1,
    records: 0,
    value: "",
    rawValue: "",
    loading: false,
    joinOptions: false,
    options: !this.props.joinOptions ? this.props.options : [],
    disabled: this.props.disabled,
    readOnly: this.props.readOnly,
    hidden: this.props.hidden,
    isValid: this.props.isValid,
    isInvalid: this.props.isInvalid,
    filter: this.props.filter,
    filteredValue: ""
  };

  componentDidMount() {
    this._isMounted = true;
    this.initializeComponent();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initializeComponent = () => {
    this.forceUpdate(() => {
      this.inInitializeComponet();
    });
  };

  inInitializeComponet = () => {
    let childField = this.props.childField;
    if (childField)
      this.updateChildField(childField);

    setTimeout(() => {
      if (!this.state.disabled)
        this.callServer();
    }, 100);
  }

  updateChildField = (childField: React.RefObject<BaseDropdown>) => {
    if (childField && childField.current) {
      let instance = childField.current;
      instance.reset();
      instance.setDisabled(true);
    }
  };

  setFilter = (filter: IFilter[]) => {
    this.setState({ filter }, () => this.callServer());
  };

  loadValue = async (valueToLoad: any) => {
    const result = this.props.options.filter(op => op.value == valueToLoad);

    if (this.props.joinOptions && result && result.length > 0) {
      const result = this.props.options.filter(op => op.value == valueToLoad);

      this.selectItem({
        label: result[0].label,
        value: result[0].value,
        item: result[0]
      }, false);
    }
    else {
      const filter = {
        operator: FilterOperator.Equals,
        property: this.props.valueProperty,
        value: valueToLoad
      };

      await this.callServer([filter]);

      if (this.props.identifier)
        this.setDisabled(true);

      if (!this._isMounted || this.state.options.length === 0)
        return;

      let label = this.state.options[0].label,
        value = this.state.options[0].value,
        item = this.state.options[0].item;

      this.selectItem({ label, value, item }, false);
    }

    if (this.props.onSetValue)
      this.props.onSetValue(valueToLoad);
  };

  selectItem = (item: any, loadChild: boolean) => {
    this.setState({
      value: item.value,
      rawValue: Util.formatRawValue(item.value, item.label, this.props.hideValueDisplay),
      isInvalid:
        !this.props.required || (this.props.required && item.value)
          ? false
          : true
    });

    if (loadChild)
      this.loadChild(this, this.props.childField?.current);

    this.fillDependentProperty(item.item);
  };

  onSelect = (record: any) => {
    if (this.props.onSelect)
      this.props.onSelect(record);
  }

  onReset = () => {
    if (this.props.onReset)
      this.props.onReset();
  }

  loadChild = (parent: BaseDropdown, child?: BaseDropdown | null) => {
    if (child) {
      setTimeout(() => {
        let filter = [
          ...child.props.filter,
          {
            property: parent.props.childFieldProperty || child.props.name,
            value: parent.state.value,
            operator: FilterOperator.Equals
          }
        ];
        child.setFilter(filter);
        child.setDisabled(false);
      }, 100);
    }
  };

  debounce = (fn: any, handler: any, delay: number): number => {
    clearTimeout(handler);
    return window.setTimeout(() => { fn(); }, delay);
  };

  callServer = async (filter: IFilter[] = []) => {

    const { endpoint, valueProperty, labelProperty } = this.props,
      { page } = this.state,
      gridParams: IServerSidePagination = {
        itemsPerPage: LIMIT_ITENS_PER_PAGE,
        page: page,
        filter: [...filter, ...this.state.filter]
      };

    let result = await Axios.get(`${endpoint}?gridParams=${JSON.stringify(gridParams)}`),
      response = result.data as IResponse;

    if (!this._isMounted)
      return;

    const { success, data, pagination } = response;

    if (success) {
      let newOptions = data.map(value => ({
        value: value[valueProperty],
        label: this.readLabelProperty(value, labelProperty),
        item: value
      }));

      this.setState((state, props) => ({
        options: state.joinOptions
          ? [...state.options, ...newOptions]
          : newOptions,
        loading: false,
        records: pagination.totalItems,
        pages: pagination.totalPages,
        page: pagination.page,
        isInvalid: props.value ? false : state.isInvalid
      }));
    }
    else
      this.setState({
        loading: false,
        options: [],
        records: 0,
        pages: 0
      });
  };

  readLabelProperty = (value: any, labelProprety: any) => {
    if (labelProprety.includes('+')) {

      var text = '';
      labelProprety.split('+').forEach((labelProp, index) => {
        text += (index > 0 ? this.props.labelSeparateProperty : '') + this.readLabelProperty(value, labelProp.trim()) ;
      });

      return text;
    }

    else if (labelProprety.includes('.')) {
      var newValue = value;

      labelProprety.split('.').forEach(labelProp => {
        newValue = newValue[labelProp];
      });

      return newValue;
    }
    else {
      return value[labelProprety];
    }

  }

  onChange = (event: any) => {
    let value = event.target.value;
    let isNumeric = Util.isOnlyNumber(value);
    this.setState({
      filteredValue: value,
      page: 1,
      debounceTimeout: this.debounce(() =>
        this.callServer(
          value
            ? [
              {
                operator: FilterOperator.Contains,
                value,
                property: isNumeric
                  ? this.props.valueProperty
                  : this.props.labelProperty
              }
            ]
            : []), this.state.debounceTimeout, 1500),
      loading: true,
      joinOptions: false
    });
  };

  reset = () => {
    if (this.state.value === this.props.value)
      return;

    this.removeFilter();

    this.setState({
      value: this.props.value || this.props.value === 0 ? this.props.value : "",
      rawValue: this.props.value || this.props.value === 0 ? this.props.value : "",
      isInvalid: this.props.isInvalid ? this.props.isInvalid : false,
      isValid: this.props.isValid ? this.props.isValid : false,
      readOnly: this.props.readOnly ? this.props.readOnly : false,
      disabled: this.props.disabled ? this.props.disabled : false,
      hidden: this.props.hidden ? this.props.hidden : false
    }, () => { this.inInitializeComponet(); });

    if (this.props.onReset)
      this.props.onReset();
  };

  setValue = (value: any, rawValue: any = undefined) => {
    this.loadValue(value);
  };

  getValue = () => this.state.value;
  getRawValue = () => this.state.rawValue;
  setDisabled = (disabled: boolean) => { this.setState({ disabled }) };
  setReadOnly = (readOnly: boolean) => this.setState({ readOnly });
  setHidden = (hidden: boolean) => this.setState({ hidden });
  setIsInvalid = (isInvalid: boolean) => this.setState({ isInvalid });
  setIsValid = (isValid: boolean) => this.setState({ isValid });

  loadPage = () => {
    const { page, pages, filter, filteredValue } = this.state;

    if (page + 1 > pages)
      return;

    const value = filter.length > 0 ? filter : [
      {
        operator: FilterOperator.Contains,
        value: filteredValue,
        property: Util.isOnlyNumber(filteredValue)
          ? this.props.valueProperty
          : this.props.labelProperty
      }
    ];

    this.setState({
      page: page + 1,
      joinOptions: true
    }, () => this.callServer(value));
  };

  removeFilter = (callback?: () => void) => {
    if (this.searchInputRef.current)
      this.searchInputRef.current.value = "";

    this.setState({
      filteredValue: "",
      page: 1,
      options: []
    }, () => { if (callback) callback() });
  };

  removeSelected = () => {
    this.setState({
      value: "",
      rawValue: ""
    });

    this.clearDependentProperty(this.props.childField?.current);

    if (this.props.onReset)
      this.props.onReset();
  };

  getAmount = () => {
    let amount = this.state.page * LIMIT_ITENS_PER_PAGE;

    if (amount > this.state.records)
      return this.state.records;
    else
      return amount;
  };

  onToggle = () => {
    setTimeout(() => {
      if (this.searchInputRef.current)
        this.searchInputRef.current.focus();
    }, 1);
  };

  fillDependentProperty = (item: any) => {
    let dependentProperty = this.props.fillDependentProperty;

    if (dependentProperty)
      dependentProperty.forEach(value => {
        value.elementRef.current.setValue(item[value.propertyName], item[value.propertyName]);
        value.elementRef.current.setDisabled(true);
      });
  };

  clearDependentProperty = (child?: BaseDropdown | null) => {
    if (child) {
      child.reset();
      child.setDisabled(true);

      if (child.props.childField)
        this.clearDependentProperty(child.props.childField.current);
    }
  };

  render() {
    const {
      isInvalid,
      isValid,
      readOnly,
      disabled,
      loading,
      records,
      options,
      page,
      pages,
      filteredValue
    } = this.state;

    const { joinOptions } = this.props;

    const onChange = (event: any) => {
      this.onChange(event);
      if (this.props.onChange)
        this.props.onChange(event);
    }

    return (
      <Dropdown alignRight onToggle={this.onToggle}>
        <Dropdown.Toggle
          {...this.props}
          id={`dropdown-toogle`}
          as={BaseDropdownToggle}
          readOnly={readOnly}
          disabled={disabled}
          isInvalid={isInvalid}
          isValid={isValid}
          onChange={onChange}
          removeSelected={this.removeSelected}
          value={this.state.value}
        >
          <option hidden={true}>{this.getRawValue()}</option>
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100 mb-0 pb-0">
          <div className="mx-3 my-2">
            <CustomSpinner
              className="spinner-input-search"
              hidden={!loading}
            />
            <Form.Control
              placeholder="Digite para pesquisar"
              size="sm"
              type="text"
              ref={this.searchInputRef}
              onChange={onChange}
              value={filteredValue}
            />
            {
              filteredValue && !loading &&
              <FontAwesomeIcon
                onClick={() => this.removeFilter(this.callServer)}
                className="search-closable"
                icon={faTimes}
                size="sm"
              />
            }
          </div>
          {
            records === 0 &&
            <Dropdown.Header className="text-center text-wrap">
              Nenhum item localizado
            </Dropdown.Header>
          }
          <div className="content-container">
            {
              joinOptions && this.props.options.length > 0 &&
              this.props.options.map((value, i) =>
                <React.Fragment key={i}>
                  <Dropdown.Item
                    key={i}
                    className="custom-dropdown-item text-wrap"
                    onClick={() => this.selectItem(value, true)}
                    onSelect={() => { this.onSelect(value) }}
                    onReset={() => this.onReset()}
                  >
                    {
                      value.value && value.label
                        ? `${value.label}` + (this.props.hideValueDisplay ? '' :` [${value.value}]`)
                        : value.value
                    }
                  </Dropdown.Item>
                  <Dropdown.Divider className="m-0" />
                </React.Fragment>)
            }
            {
              options && options.map((value, i) =>
                <React.Fragment key={i}>
                  <Dropdown.Item
                    key={i}
                    className="custom-dropdown-item text-wrap"
                    onClick={() => this.selectItem(value, true)}
                    onSelect={() => { this.onSelect(value) }}
                    onReset={() => this.onReset()}
                  >
                    {
                      value.value && value.label
                      ? `${value.label}` + (this.props.hideValueDisplay ? '' :` [${value.value}]`)
                        : value.value
                    }
                  </Dropdown.Item>
                  <Dropdown.Divider className="m-0" />
                </React.Fragment>)
            }
          </div>
          {
            records > 0 &&
            <Dropdown.Header>
              <div className="text-center text-wrap">
                <span className="mr-1">
                  {this.getAmount()} de {records}
                </span>
              </div>
              <div className="text-center">
                <Button
                  block
                  disabled={page === pages}
                  onClick={this.loadPage}
                  variant="link"
                  id={`dropdown-btn-add`}
                  size="sm"
                >
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </Button>
              </div>
            </Dropdown.Header>
          }
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

BaseDropdown.defaultProps = {
  loading: false,
  valueProperty: "id",
  labelProperty: "description",
  labelSeparateProperty: "/",
  hideValueDisplay: false,
  type: "number",
  options: [],
  filter: []
};

export default BaseDropdown;

interface IBaseDropdownToggleProps extends ICommonInputProps {
  removeSelected: () => void;
  value: any;
}

const BaseDropdownToggle = React.forwardRef<any, IBaseDropdownToggleProps>((props, ref) => {
  const [showFeedback, setShowFeedback] = useState(true);

  useEffect(() => {
    setShowFeedback(true);
  }, [props.isInvalid, props.isValid, props.value]);

  const onClick = (e: any) => {
    e.preventDefault();

    setShowFeedback(false);

    if (props.onClick)
      props.onClick(e);
  };

  return (
    <Form.Group>
      <CustomSpinner
        className="spinner-select"
        hidden={!props.loading}
      />
      <CommonLabel
        id={props.id}
        label={props.label}
        required={props.required}
        informationMessage={props.informationMessage}
      />
      <InputGroup ref={ref}>
        <CustomSpinner
          className="spinner-input-form"
          hidden={!props.loading}
        />
        <Form.Control
          as="select"
          size="sm"
          className="custom-select custom-select-sm"
          onClick={onClick}
          readOnly={props.readOnly}
          disabled={props.disabled}
          isInvalid={props.isInvalid}
          value={props.value}
          id={props.id}
          name={props.name}
          required={props.required}
          onBlur={props.onBlur}
          onChange={props.onChange}
        >
          {props.children}
        </Form.Control>
        {
          props.value &&
          <CustomInputGroupAppend
            disabled={props.disabled}
            readOnly={props.readOnly}
            append={{
              buttonProps: {
                variant: "secondary"
              },
              icon: faTrash,
              onClick: props.removeSelected,
              hideWhenRead: true
            }}
          />
        }
        {
          showFeedback &&
          <CheckHasFeedback
            value={props.value}
            feedback={props.feedback}
            isInvalid={props.isInvalid}
            isValid={props.isValid}
            required={props.required}
          />
        }
      </InputGroup>
    </Form.Group>
  );
});
