import React from 'react';
import DateInput, { IDateInputProps } from '../date';

export interface IDateRangeInputChildProps extends 
Omit<Partial<IDateInputProps>,'startDate' | 'endDate' | 'minTime' | 'maxTime'
| 'selected' | 'selectsStart' | 'selectsEnd'> {}
 
export interface IDateRangeProps {
    startDate: IDateRangeInputChildProps,
    endDate: IDateRangeInputChildProps
}

export default class DateRangeInput extends React.Component<IDateRangeProps> {
    static defaultProps: IDateRangeProps;

    state = {
        startDate: null,
        endDate: null,
        minDateStart: null,
        maxDateStart: null,
        minDateEnd: null,
        maxDateEnd: null
    }

    componentDidMount(){
        this.state.minDateStart=this.props.startDate.minDate;
        this.state.maxDateStart= this.props.startDate.maxDate;
        this.state.minDateEnd= this.props.endDate.minDate;
        this.state.maxDateEnd= this.props.endDate.maxDate;
    }
    
 
    setStartDate = (startDate: Date | null) => {
        this.setState(() => ({
            startDate
        })
        , () => {
            if (startDate){
                if(this.props.endDate.minDate){
                    if (startDate > this.props.endDate.minDate){
                        this.setState({minDateEnd: startDate});
                    }
                    else {
                        this.setState({minDateEnd: this.props.endDate.minDate});
                    }
                }
                else this.setState({minDateEnd: startDate});
            }
            else{
                if(this.props.endDate.minDate){
                    this.setState({minDateEnd: this.props.endDate.minDate});
                }
                else this.setState({minDateEnd: null});
            }
        });
    }

    setEndDate = (endDate: Date | null): any => {
        this.setState(() => ({
            endDate
        })
        , () => {
            if (endDate){
                if(this.props.startDate.maxDate){
                    if (endDate < this.props.startDate.maxDate){
                        this.setState({maxDateStart: endDate});
                    }
                    else{
                        this.setState({maxDateStart: this.props.startDate.maxDate});
                    }
                }
                else this.setState({maxDateStart: endDate});
            }
            else{
                if(this.props.startDate.maxDate){
                    this.setState({maxDateStart: this.props.startDate.maxDate});
                } 
                else this.setState({maxDateStart: null});
            }
        });
    }

    render() {
        const { startDate, endDate } = this.props;

        const { startDate: startDateState, endDate: endDateState,
            minDateStart: startMinDate, maxDateStart: startMaxDate,
            minDateEnd: endMinDate, maxDateEnd: endMaxDate} = this.state;

        return (
            <>
                <DateInput
                    {...startDate}
                    selected={startDateState} 
                    startDate={startDateState} 
                    endDate={endDateState}
                    minDate={startMinDate}
                    maxDate={startMaxDate}
                    selectsStart 
                    callbackChange={(date) => {
                        this.setStartDate(date)
                        if (startDate.callbackChange){
                            endDate.callbackChange(date);
                        }
                    }}
                    ref={startDate.forwardedRef}
                />
                <DateInput
                    {...endDate}
                    selected={startDateState} 
                    startDate={startDateState} 
                    endDate={endDateState}
                    minDate={endMinDate}
                    maxDate={endMaxDate}
                    selectsEnd 
                    callbackChange={(date) => { 
                        this.setEndDate(date) 
                        if (endDate.callbackChange){
                            endDate.callbackChange(date);
                        }
                    }}
                    ref={endDate.forwardedRef}
                />
            </>
        );
    }
}

DateRangeInput.defaultProps = {
    startDate: {
        label: "Data início",
        name: "startDate",
        id: "startdate-datepicker"
    },
    endDate: {
        label: "Data fim",
        name: "endDate",
        id: "enddate-datepicker"
    }
}