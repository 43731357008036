import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Util from '../components/util';

var _token: string = '';

Axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const user = parseInt(Util.getUrlParams().get("p_id_usuario") || '0')
    config.headers.common["Authorization"] = `Bearer ${_token}`;
    config.headers.common["userMoveId"] = user;
    return config;
});

Axios.interceptors.response.use((response: AxiosResponse) => response, error => {
    let code = error && error.response && error.response.status ? error.response.status : 0;

    switch (code) {
        case 401:
            return Promise.resolve({
                data: {
                    success: false,
                    error: {
                        code: code,
                        message: "Usuário logado não possui permissão"
                    },
                    messages: [
                        { 
                            index: 0, 
                            message: "Usuário logado não possui permissão" 
                        }
                    ]
                }
            });
        default:
            return Promise.reject(error);
    }
});


class BaseService {
    private axiosInstance: any = null;

    setBaseEndpoint = (baseUrl: string) => {
        this.axiosInstance = Axios.create({ 
            baseURL: baseUrl
        });
    }

    setToken = (token: string) =>{
        _token = token;
    }
    
    get = async (endpoint: string) => {
        return await this.axiosInstance.get(endpoint);
    };
    
    post = async (endpoint: string, data: any) => {
        return await this.axiosInstance.post(endpoint, data);
    };
    
    put = async (endpoint: string, data: any) => {
        return await this.axiosInstance.put(endpoint, data);
    };
    
    delete = async (endpoint: string) => {
        return await this.axiosInstance.delete(endpoint);
    };
}

export default BaseService;