import { conformToMask } from "react-text-mask";
import { isDate } from "util";
import { IAddress } from "../input/cep";

const localePTBR = "pt-BR";

export default class Util {
  static getNestedObject = (nestedObj: any, pathArr: any): any => {
    return pathArr.reduce(
      (obj: any, key: any) =>
        obj && obj[key] !== "undefined" ? obj[key] : undefined,
      nestedObj
    );
  };

  static getObjectValue = (data: any, key: string) => {
    if (data) {
      if (!data.hasOwnProperty(key)) {
        console.warn(`chave [${key}] não localizada na model -->`, data);
        return null;
      } else return data[key];
    }

    console.warn(`chave [${key}] não localizada. Valor null`);
    return null;
  };

  static getObjectValueFromObject = (data: any, key: string): any => {
    let keys = key.split(".");
    return Util.getNestedObject(data, keys);
  };

  static openMapWindow = (address: IAddress) => {
    let street = address.streetNumber
        ? `${address.street}, ${address.streetNumber}`
        : address.street,
      query = `${street} - ${address.district}, ${address.city} - ${address.state}, ${address.zipCode}`;

    window.open(`https://www.google.com/maps/place/${query}`);
  };

  static openWhatsappWindow = (
    phoneNumber: string,
    message: string = "Olá"
  ) => {
    window.open(
      `https://api.whatsapp.com/send?phone=+55${phoneNumber}&text=${message}`
    );
  };

  static openMailTo = (email: string) => {
    window.open(`mailto:${email}`, "_top");
  };

  static dateToISO = (date: string) => {
    return new Date(date).toISOString();
  };

  static objectsAreTheSame = (x: any, y: any): boolean => {
    for (let key in x) if (x[key] !== y[key]) return false;

    return true;
  };

  static dateISOToDate = (date: string | Date, locale: string = localePTBR) => {
    date = isDate(date) ? date : new Date(date);
    return date.toLocaleDateString(locale);
  };

  static dateISOToTime = (date: string | Date, locale: string = localePTBR) => {
    date = isDate(date) ? date : new Date(date);
    return date.toLocaleTimeString(locale);
  };

  static dateISOToDateTime = (
    date: string | Date,
    locale: string = localePTBR
  ) => {
    date = isDate(date) ? date : new Date(date);
    return date.toLocaleString(locale);
  };

  static formatRawValue = (value: any, label: any, hideValue: boolean): string => {    
    return label ? `${label}` + (hideValue ? '' :` [${value}]`) : value;
  };

  static conformToMask = (value: any, mask: any) => {
    let _value = value || value === 0 ? value.toString() : "";

    _value = _value.replace(".", ",");

    let _mask = typeof mask === "function" ? mask(_value) : mask;
    //Bug alternative resolve for react-text-mask lib.
    _mask = _mask.filter(item => item != "[]");
 
    let masked = conformToMask(_value, _mask, {
      guide: false
    });

    return masked.conformedValue;
  };

  static getUrlParams = () => {
    return new URLSearchParams(window.location.href.replace(/^.*\?/gm,"?"));
  };

  static checkIfValueExistsInList = (value: any, list: Array<any>): boolean => {
    return list ? list.filter(item => item === value).length > 0 : false;
  };

  static onlyNumbers = (value: string, replace: string = "") => {
    return value ? value.replace(/\D+/g, replace) : value;
  };

  static onlyAlphaCharacters = (value: string, replace: string = "") => {
    return value ? value.replace(/\W+/g, replace) : value;
  };

  static getDecimalNumber = (value: string) => {
    return Number(value.replace(/[^0-9,]+/g, "").replace(",", "."));
  };

  static isOnlyNumber = (value: string) => {
    return !new RegExp(/[^0-9]/).test(value);
  };
}
