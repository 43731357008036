import React from "react";
import BaseInput, { IBaseInputProps } from '../input';
import { ZipCodeService } from "../../../services";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Util from "../../util";

export const CEP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

export interface IAddress {
    zipCode: string,
    street: string,
    streetNumber?: string,
    complement?: string,
    district: string,
    city: string,
    state: string,
}

export interface ICepInputProps extends IBaseInputProps {
    showMapsButton?: boolean,
    callbackAddress?(address: IAddress): Promise<void> | void | undefined,
    forwardedRef: any
}

export const MapsWindowButton = (props: { label: string, address: IAddress }) => {
    const { address, label } = props;

    return <>
        {label}
        {checkAddress(address) &&
            <Button
                size="sm"
                onClick={() => Util.openMapWindow(address)}
                variant="link"
            >
                <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    size="lg"
                />
            </Button>}
    </>;
}

export const checkAddress = (address: IAddress): boolean => {
    return address.street &&
        address.city &&
        address.state &&
        address.zipCode ?
        true : false;
}

class CepInput extends React.Component<ICepInputProps> {
    static defaultProps: Partial<IBaseInputProps>;

    state = {
        loading: false,
        isValid: false,
        address: {} as IAddress
    }

    initializeComponent = async (value: any) => {
        this.setState({
            loading: true
        });

        let isValid = await this.checkIfIsValid(value),
            forwardedRef = this.props.forwardedRef;

        if (forwardedRef) {
            let instance = forwardedRef.current;

            if (value && isValid)
                instance.setIsInvalid(false);
            else if (value && isValid === false)
                instance.setIsInvalid(true);
            else
                instance.setIsInvalid(false);
        }

        this.setState({
            isValid,
            loading: false,
            address: isValid ? this.state.address : {}
        }, () => {
            if (this.props.callbackAddress) this.props.callbackAddress(this.state.address);
        });
    }

    onBlur = async (event: any) => {
        let value = event.target.value.replace(/[^\d]+/g, '');
        this.initializeComponent(value);
    }

    checkIfIsValid = async (value: any) => {
        if (!value || value.length < 8) return false;

        let result = await ZipCodeService.get(value.replace(/\D/g, ''));
        if (!result.data.success) return false;

        let address: IAddress = {
            zipCode: value,
            street: result.data.data.addressLine,
            district: result.data.data.neighborhood,
            city: result.data.data.city.id,
            state: result.data.data.city.state.id
        };

        this.setState({ address });

        return true;
    }

    render() {
        const {
            props,
            state,
            initializeComponent,
            onBlur
        } = this,
            {
                showMapsButton,
                forwardedRef
            } = props,
            {
                address,
                loading
            } = state;

        let dynamicProps: any = {};

        if (showMapsButton && checkAddress(address)) {
            dynamicProps.append = {
                buttonProps: {
                    variant: "secondary"
                },
                icon: faMapMarkerAlt,
                onClick: () => Util.openMapWindow(address)
            };
        }

        return (
            <BaseInput
                {...props}
                mask={CEP_MASK}
                onBlur={onBlur}
                loading={loading}
                ref={forwardedRef}
                onSetValue={initializeComponent}
                {...dynamicProps}
            />
        );
    }
}

CepInput.defaultProps = {
    label: 'CEP',
    feedback: {
        invalid: "Informe um CEP válido"
    }
}

export default React.forwardRef<BaseInput, Omit<Partial<ICepInputProps>, 'forwardedRef'>>((props, ref) => {
    return <CepInput {...props} forwardedRef={ref} />
});
