import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Axios from "axios";
import { IResponse } from "../../../interfaces/IResponse";
import { IServerSidePagination } from "../../grid";
import { 
    ICommonInputProps, 
    ICommonInputState, 
    CommonLabel, 
    CustomInputGroupPrepend, 
    CustomInputGroupAppend, 
    CheckHasFeedback, 
    CustomSpinner 
} from "../base";

export interface IBaseSelectProps extends ICommonInputProps
{
    value?: any;
    options?: ISelectOptions[];
    joinOptions?: boolean;
    endpoint?: string;
    valueProperty?: string;
    labelProperty?: string;
    loading?: boolean;
    type: 'string' | 'number';
    autoComplete?: boolean;
    multiple?: boolean;
    size?: any;
}

export interface ISelectOptions
{
    value: any;
    label?: any;
}

interface IBaseSelectState extends ICommonInputState
{
    value: any;
    rawValue: any;
    loading: boolean;
    options?: ISelectOptions[];
}

class BaseSelect extends React.Component<IBaseSelectProps, IBaseSelectState>
{
    static defaultProps: Partial<IBaseSelectProps>;
    private _isMounted: boolean = false;

    state: IBaseSelectState = {
        value: '',
        rawValue: '',
        loading: false,
        options: !this.props.joinOptions ? this.props.options : [],
        disabled: this.props.disabled,
        readOnly: this.props.readOnly,
        hidden: this.props.hidden,
        isValid: this.props.isValid,
        isInvalid: this.props.isInvalid
    }

    static getDerivedStateFromProps(props: IBaseSelectProps, state: IBaseSelectState)
    {
        if (!props.endpoint && props.loading !== state.loading)
            return { loading: props.loading };
        
        return null;
    }

    private async getData()
    {
        const { endpoint, valueProperty, labelProperty } = this.props;

        this.setState({ loading: true }); 

        const gridParams: IServerSidePagination = {
            page: 0,
            itemsPerPage: 0,
            filter: [],
            sorter: []
        };
        
        let result = await Axios.get(`${endpoint}?gridParams=${JSON.stringify(gridParams)}&userId=0`),
            response = result.data as IResponse;

        const { success, data } = response;
    
        if (this._isMounted)
            this.setState({
                options: success ? data.map(value => ({ value: value[valueProperty!], label: value[labelProperty!] })) : [],
                loading: false
            });
    }

    async componentDidMount()
    {
        this._isMounted = true;

        const { endpoint, value, multiple, joinOptions } = this.props;

        if (endpoint)
            await this.getData();

        if (this._isMounted)
            this.setState((state) => ({
                options: state.options
                        ? multiple || joinOptions ? state.options : [ { value: '', label: ''}, ...state.options ]
                        : undefined,
                value: value ? value : state.value
            }));
    }

    async componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps.endpoint != this.props.endpoint)
            await this.getData();
    }

    componentWillUnmount()
    {
        this._isMounted = false;
    }

    onChange = (event: any) =>
    {
        let value = event.target.value,
            rawValue = event.target.options[event.target.selectedIndex]
                        ? event.target.options[event.target.selectedIndex].text
                        : "";

        this.setValue(value, rawValue);

        this.props.onChange && this.props.onChange(event);
    }

    onBlur = (event: any) =>
    {
        let value = event.target.value;

        if (this.props.required && value)
        {
            this.setState({
                isInvalid: false
            });
        }

        if (this.props.onBlur) this.props.onBlur(event);
    }

    reset = () =>
        this.setState({
            value: this.props.value || '',
            isInvalid: this.props.isInvalid ? this.props.isInvalid : false,
            isValid: this.props.isValid ? this.props.isValid : false,
            readOnly: this.props.readOnly ? this.props.readOnly : false,
            disabled: this.props.disabled ? this.props.disabled : false,
            hidden: this.props.hidden ? this.props.hidden : false
        });

    setValue = (value: any, rawValue: any = undefined) =>
        this.setState({ 
            value, 
            rawValue
        });

    getValue = () => this.state.value;
    getRawValue = () => this.state.rawValue;

    setDisabled = (disabled: boolean) => this.setState({ disabled });
    setReadOnly = (readOnly: boolean) => this.setState({ readOnly });
    setHidden = (hidden: boolean) => this.setState({ hidden });
    setIsInvalid = (isInvalid: boolean) => this.setState({ isInvalid });
    setIsValid = (isValid: boolean) => this.setState({ isValid });

    render()
    {
        const { 
            props, 
            state, 
            onChange, 
            onBlur 
        } = this;

        return (
            <Form.Group hidden={props.hidden}>
                <CustomSpinner 
                    className="spinner-input" 
                    hidden={!state.loading} 
                />
                <CommonLabel 
                    id={props.id}
                    label={props.label}
                    required={props.required}
                    informationMessage={props.informationMessage}
                />
                <InputGroup>
                    <CustomInputGroupPrepend 
                        disabled={state.disabled} 
                        readOnly={state.readOnly} 
                        prepend={props.prepend}
                    />

                    <Form.Control
                        as="select" 
                        size={props.size || "sm"}
                        bsPrefix="custom-select"
                        readOnly={state.readOnly}
                        disabled={state.disabled}
                        isValid={state.isValid}
                        isInvalid={state.isInvalid}
                        value={state.value}
                        id={props.id}
                        name={props.name}
                        type={props.type}
                        required={props.required}
                        multiple={props.multiple}
                        onChange={onChange}
                        onBlur={onBlur}
                        onClick={props.onClick}
                        style={props.style}
                    >
                        {
                            props.joinOptions && props.options.length > 0 &&
                                props.options.map((value, i) =>
                                    <option
                                        key={i} 
                                        value={value.value}
                                    >
                                        {
                                            value.value != undefined && 
                                            value.value !== '' && 
                                            value.label ? 
                                                `${value.label} [${value.value}]` : 
                                                value.value
                                        }
                                    </option>)
                        }
                        {
                            state.options && state.options.map((value, i) =>
                                <option 
                                    key={i} 
                                    value={value.value}
                                >
                                    {
                                        value.value != undefined && 
                                        value.value !== '' && 
                                        value.label ? 
                                            `${value.label} [${value.value}]` : 
                                            value.value
                                    }
                                </option>
                        )}
                    </Form.Control>
                    <CustomInputGroupAppend  
                        disabled={state.disabled} 
                        readOnly={state.readOnly} 
                        append={props.append}
                    />
                    <CheckHasFeedback 
                        value={state.value}
                        feedback={props.feedback}
                        isInvalid={state.isInvalid}
                        isValid={state.isValid}
                        required={props.required}
                    />
                </InputGroup>
            </Form.Group>
        )
    }
}

BaseSelect.defaultProps = {
    loading: false,
    valueProperty: 'id',
    labelProperty: 'description',
    type: 'number'
}

export default BaseSelect;