import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye,
    faEdit,
    faPlus,
    faHistory,
    faSearch,
    faCloudUploadAlt,
    faTrash,
    faEllipsisH
} from '@fortawesome/free-solid-svg-icons';
import {
    Button,
    Row,
    Col,
    Badge,
    Dropdown
} from 'react-bootstrap';
import BaseModal, { TypesBaseModal } from '../modal';
import BaseLog from '../log';
import BaseLogSQL from '../log-sql';
import BaseExport from '../export';
import UserContext from '../context/user';
import BaseForm from '../form';
import {
    IBaseToolbarProps,
    IBaseToolbarState
} from './types';

import './styles.scss';
import { CpfInput } from '..';

export default class BaseToolbar extends React.Component<IBaseToolbarProps, IBaseToolbarState> {
    context!: React.ContextType<typeof UserContext>;
    private baseModalRef = React.createRef<BaseModal>();
    static defaultProps: IBaseToolbarProps;

    state: IBaseToolbarState = {
        hideLoading: true,
        modal: {
            show: false,
            type: TypesBaseModal.read,
            content: this.props.crud.form,
            callbackAfterClose: () => undefined,
            data: this.props.data,
            name: this.props.name
        }
    }

    private formatEndpoint = (endpoint: string): string => {
        if (endpoint)
            return `${this.props.baseEndpoint}/${endpoint}`;

        return this.props.baseEndpoint!;
    }

    private onClickCreate = () => {
        let form = React.cloneElement(
            this.props.crud.form,
            {
                type: TypesBaseModal.create,
                baseModalRef: this.baseModalRef,
                filter: this.props.filter,
                callbackFilter: this.props.callbackFilter,
                endpoint: this.formatEndpoint(this.props.crud.create!.endpoint!),
                dependentProperty: this.props.dependentProperty,
                name: this.props.name
            }
        );

        this.showModal(TypesBaseModal.create, form, "xl");
    }

    private onClickUpdate = () => {
        const form = React.cloneElement(
            this.props.crud.form,
            {
                data: this.props.data,
                type: TypesBaseModal.update,
                baseModalRef: this.baseModalRef,
                filter: this.props.filter,
                callbackFilter: this.props.callbackFilter,
                callbackRemoveSelectedItems: this.props.callbackRemoveSelectedItems,
                endpoint: this.formatEndpoint(this.props.crud.update!.endpoint!),
                dependentProperty: this.props.dependentProperty,
                name: this.props.name
            }
        );

        this.showModal(TypesBaseModal.update, form, "xl");
    }

    private onClickDelete = () => {
        const {
            props,
            baseModalRef,
            formatEndpoint
        } = this,
            {
                data,
                filter,
                callbackFilter,
                callbackRemoveSelectedItems,
                crud,
                dependentProperty,
                identifiers,
                name,
                deleteId
            } = props;

        let form = (
            <BaseForm
                data={data}
                type={TypesBaseModal.delete}
                baseModalRef={baseModalRef}
                filter={filter}
                callbackFilter={callbackFilter}
                callbackRemoveSelectedItems={callbackRemoveSelectedItems}
                endpoint={formatEndpoint(crud.delete!.endpoint!)}
                dependentProperty={dependentProperty}
                identifiers={identifiers}
                refs={[]}
                name={name}
                deleteId={deleteId}
            />
        );

        this.showModal(
            TypesBaseModal.delete,
            form,
            'sm'
        );
    }

    private onClickDetail = () => {
        let form = React.cloneElement(
            this.props.crud.form,
            {
                data: this.props.data,
                type: TypesBaseModal.read,
                baseModalRef: this.baseModalRef,
                name: this.props.name
            }
        );

        this.showModal(
            TypesBaseModal.read,
            form,
            'xl'
        );
    }

    private onClickSearch = () => {
        let form = React.cloneElement(
            this.props.search.form,
            {
                type: TypesBaseModal.search,
                baseModalRef: this.baseModalRef,
                filter: this.props.filter,
                callbackFilter: this.props.callbackFilter,
                name: this.props.name
            }
        );

        this.showModal(
            TypesBaseModal.search,
            form,
            "xl"
        );
    }

    private showModal = (type: TypesBaseModal, content: any, size: "xl" | "sm" | "lg") => {
        this.setState((state) => ({
            modal: {
                ...state.modal,
                content,
                show: true,
                size,
                type
            }
        }));
    }

    private hideModal = () => {
        this.setState((state) => ({
            modal: {
                ...state.modal,
                show: false
            }
        }));
    }

    private showLog = () => {
        const { id, keys } = this.props.crud.log!;
        const content = <BaseLog
            name={this.props.name}
            id={id}
            keys={keys}
            data={this.props.data}
        />;

        this.setState(() => ({
            modal: {
                ...this.state.modal,
                content,
                show: true,
                size: "xl",
                type: TypesBaseModal.log
            }
        }));
    }

    private showLogSQL = () => {
        const { id, endpoint } = this.props.crud.logSQL!;
        const content = <BaseLogSQL
            id={id}
            endpoint={endpoint}
        />;

        this.setState(() => ({
            modal: {
                ...this.state.modal,
                content,
                show: true,
                size: "xl",
                type: TypesBaseModal.logSQL
            }
        }));
    }

    showExport = () => {
        const { exportGrid } = this.props.crud;

        return exportGrid && exportGrid.show &&
            (!exportGrid.permissionId || (exportGrid.permissionId &&
                this.checkIfHasPermission(exportGrid.permissionId)));
    }

    showUpdate = () => {
        const { update } = this.props.crud;

        return update && update.show &&
            (!update.permissionId || (update.permissionId &&
                this.checkIfHasPermission(update.permissionId)));
    }

    showCreate = () => {
        const { create } = this.props.crud;

        return create && create.show &&
            (!create.permissionId || (create.permissionId &&
                this.checkIfHasPermission(create.permissionId)));
    }

    showDelete = () => {
        let deleteForm = this.props.crud.delete;

        return deleteForm && deleteForm.show &&
            (!deleteForm.permissionId || (deleteForm.permissionId &&
                this.checkIfHasPermission(deleteForm.permissionId)));
    }

    showUpload = () => {
        const { upload } = this.props.crud;
        return upload && upload.show &&
            (!upload.permissionId || (upload.permissionId &&
                this.checkIfHasPermission(upload.permissionId)));
    }

    checkIfHasPermission = (permissionId: string) => {
        const { checkIfHasPermission, permissionIdList } = this.context;
        return checkIfHasPermission(permissionId, permissionIdList);
    }

    render() {
        const {
            props,
            state,
            hideModal,
            baseModalRef,
            showExport,
            showLog,
            showLogSQL,
            showCreate,
            showUpdate,
            showDelete,
            showUpload,
            context,
            onClickSearch,
            onClickCreate,
            onClickUpdate,
            onClickDelete,
            onClickDetail,
            checkIfHasPermission
        } = this,
            {
                data,
                name,
                crud,
                selectedItems,
                gridParams,
                identifiers,
                baseEndpoint,
                search,
                filter
            } = props,
            {
                exportGrid
            } = crud,
            {
                modal
            } = state;
        return (
            <div className="border-bottom toolbar bg-white">
                <BaseModal
                    content={modal.content}
                    size={modal.size}
                    type={modal.type}
                    show={modal.show}
                    callbackAfterClose={hideModal}
                    data={data}
                    ref={baseModalRef}
                    name={name}
                />
                <Row>
                    <Col className="left-toolbar text-left">
                        {
                            crud.log &&
                            crud.log.show &&
                            <Button
                                type="button"
                                onClick={showLog}
                                size="sm"
                                disabled={selectedItems.length === 0}
                            >
                                <FontAwesomeIcon
                                    icon={faHistory}
                                /> Log
                            </Button>
                        }

                        {
                            crud.logSQL &&
                            crud.logSQL.show &&
                            <Button
                                type="button"
                                onClick={showLogSQL}
                                size="sm"
                                disabled={selectedItems.length === 0}
                            >
                                <FontAwesomeIcon
                                    icon={faHistory}
                                /> Log
                            </Button>
                        }

                        {
                            showExport() &&
                            <BaseExport
                                gridParams={gridParams}
                                mode="modal"
                                selectedItems={selectedItems}
                                identifiers={identifiers}
                                endpoint={baseEndpoint}
                                userId={context.userId}
                                types={(exportGrid) ? exportGrid.types : undefined}
                            />
                        }

                        {
                            crud.upload &&
                            crud.upload.show &&
                            showUpload() &&
                            <Button
                                type="button"
                                size="sm"
                                variant="success"
                                onClick={crud.upload.onClick}
                            >
                                <FontAwesomeIcon
                                    icon={faCloudUploadAlt}
                                /> Upload
                            </Button>
                        }
                    </Col>
                    <Col className="right-toolbar text-right">
                        {
                            search &&
                            search.show &&
                            <Button
                                type="button"
                                onClick={onClickSearch}
                                size="sm"
                            >
                                <FontAwesomeIcon icon={faSearch} />
                                &nbsp;
                                Pesquisar
                                &nbsp;
                                <Badge
                                    variant="secondary"
                                >
                                    {
                                        filter ?
                                            filter.filter(value => value.closeable !== false).length :
                                            0
                                    }
                                </Badge>
                            </Button>
                        }
                        {
                            showCreate() &&
                            <Button
                                type="button"
                                onClick={onClickCreate}
                                size="sm"
                                variant="success"
                                disabled={crud.create.disable}
                            >
                                <FontAwesomeIcon
                                    icon={faPlus}
                                /> Novo
                            </Button>
                        }

                        {
                            showUpdate() &&
                            <Button
                                type="button"
                                onClick={onClickUpdate}
                                size="sm"
                                variant="warning"
                                disabled={selectedItems.length !== 1 || crud.update.disable}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                /> Editar
                            </Button>
                        }

                        {
                            showDelete() &&
                            <Button
                                type="button"
                                onClick={onClickDelete}
                                size="sm"
                                variant="danger"
                                disabled={selectedItems.length !== 1 || crud.delete.disable}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}
                                /> Excluir
                            </Button>
                        }

                        {
                            crud.read &&
                            crud.read.show &&
                            <Button
                                type="button"
                                onClick={onClickDetail}
                                size="sm"
                                disabled={selectedItems.length === 0}
                            >
                                <FontAwesomeIcon
                                    icon={faEye}
                                /> Detalhar
                            </Button>
                        }

                        {
                            crud.additionalButtons &&
                            <Dropdown
                                drop="down"
                                alignRight
                                className="custom-dropdown"
                            >
                                <Dropdown.Toggle
                                    variant="light"
                                    id={`${name}-dropdown-toolbar-columns-options-grid`}
                                    size="sm"
                                    disabled={crud.additionalButtons.every(value => value.disabled)}
                                >
                                    <FontAwesomeIcon
                                        icon={faEllipsisH}
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {
                                        crud.additionalButtons.map((value, i) => (
                                            (!value.permissionId || (
                                                value.permissionId && checkIfHasPermission(value.permissionId)
                                            )) &&
                                            <Dropdown.Item
                                                key={i} disabled={value.disabled}
                                                onClick={value.onClick}
                                            >
                                                {
                                                    value.icon &&
                                                    <FontAwesomeIcon
                                                        className="mr-1"
                                                        icon={value.icon}
                                                    />
                                                }
                                                {value.name}
                                            </Dropdown.Item>
                                        ))
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </Col>
                </Row>
            </div >
        );
    }
}

BaseToolbar.contextType = UserContext;