import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 16px 20px 16px 16px;
  margin: 16px 20px;
  overflow-y: auto;
  overflow-x: hidden;

  div:nth-child(even) {
    float: right;
    clear: right;
  }

  div:nth-child(odd) {
    float: left;
    clear: left;
  }

  @media screen and (max-height: 1200px){
    max-height: 600px;
  }

  @media screen and (max-height: 900px){
    max-height: 500px;
  }

  @media screen and (max-height: 768px){
    max-height: 400px;
  }

  @media screen and (max-height: 668px){
    max-height: 350px;
  }
`;

interface LogProps {
  isPair: boolean;
}

export const Log = styled.div<LogProps>`
  width: 100%;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Divider = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-1px);

  width: 2px;
  height: 100%;

  background: #2F619D;
`;

export const Card = styled.div<LogProps>`
  background: #EEEEEE;
  border-radius: 12px;

  display: grid;

  font-weight: bold;
  font-size: 14px;

  width: 50%;
  ${props => !props.isPair ? css`transform: translateX(-17px);` : css`transform: translateX(17px);`}

  padding: 16px;
  margin: 8px 0;

  position: relative;

  order: ${props => props.isPair && 2};

  &:before {
    content: '';

    position: absolute;

    ${props => props.isPair ? css`left: -30px;` : css`right: -30px;`}
    top: 50%;
    transform: translateY(-50%);

    width: 26px;
    height: 26px;

    border-radius: 50%;

    background: #2F619D;
  }

  &:after {
    content: '';

    position: absolute;

    ${props => props.isPair ? css`left: -15px;` : css`right: -15px;`}

    top: 50%;
    transform: translateY(-50%);

    width: 0px;
    height: 0px;

    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;

    ${props => props.isPair
        ? css`border-right: 16px solid #EEEEEE;`
        : css`border-left: 16px solid #EEEEEE;`
      }
  }

  div {
    display: block;

    strong {
      color: #2F619D;
    }

    span {
      color: #222222;
      opacity: 50%;
      text-decoration: line-through;
    }
  }
`;

export const DateInfo = styled.div<LogProps>`
  background: transparent;
  border-radius: 12px;

  display: grid;

  font-weight: bold;
  font-size: 12px;

  width: 50%;

  padding: 16px;
  margin: 8px 0;

  position: relative;

  div {
    display: block;
    font-size: 1.2em;
    padding: 0 8px;

    ${props => props.isPair && css`text-align: end;`}

    strong {
      color: #000000;
    }

    span {
      color: #222222;
      opacity: 50%;
      text-decoration: line-through;
    }
  }
`;

export const Feedback = styled.div`
  background: #EEEEEE;
  border-radius: 12px;

  width: 100%;
  padding: 16px;

  strong {
    color: #222222;
  }
`;
