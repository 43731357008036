import React from 'react';
import BaseInput, { IBaseInputProps } from '../input';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Util from '../../util';

export const DEFAULT_CURRENCY_MASK: CurrencyMaskOptions = {
    prefix: 'R$ ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 11,
    allowNegative: true,
    requireDecimal: false,
    allowLeadingZeroes: false
};

interface CurrencyMaskOptions {
    prefix?: string,
    suffix?: string,
    includeThousandsSeparator?: boolean,
    thousandsSeparatorSymbol?: string,
    allowDecimal?: boolean,
    decimalSymbol?: string,
    decimalLimit?: number,
    integerLimit?: number,
    requireDecimal?: boolean,
    allowNegative?: boolean,
    allowLeadingZeroes?: boolean
}

interface ICurrencyProps extends IBaseInputProps {
    forwardedRef: any,
    maskOptions: CurrencyMaskOptions,
    onBlurCalback?: (event: any) => void,
}

interface finalProps extends Omit<Partial<ICurrencyProps>, 'forwardedRef' | 'mask' | 'onBlur' | 'isCurrency' 
| 'isDate' | 'isEmail' | 'maxLength' | 'checked' | 'notAllowSpecialCharacters' | 'as' | 'type' | 'transformCase'
| 'step' | 'feedback' | 'inheritanceComponent'>{}

class CurrencyInput extends React.Component<ICurrencyProps> {
    static defaultProps: Partial<ICurrencyProps>;

    private currencyMaskMerged = {
        ...DEFAULT_CURRENCY_MASK,
        ...this.props.maskOptions
    }

    private currencyMask = createNumberMask(this.currencyMaskMerged);

    private messageInvalid='Informe um valor válido';

    onDefineMsgInvalid = () =>
    {
        return this.messageInvalid;
    }
    
    onBlur = (event: any) => {
        let value = event.target.value,
            isValid = this.checkIfIsValid(value),
            forwardedRef = this.props.forwardedRef;

        if (forwardedRef) {
            let instance = forwardedRef.current;

            instance.setValue(value);

            if (value && isValid)
                instance.setIsInvalid(false);
            else if (value && !isValid)
                instance.setIsInvalid(true);
            else if (!value)
                instance.setIsValid(false);
            else
                instance.setIsInvalid(false);
        }

        if (this.props.onBlurCalback)
            this.props.onBlurCalback(event);           
    }

    setNewMask = (mask: CurrencyMaskOptions, callback?: () => void)=>{
        let forwardedRef = this.props.forwardedRef;

        if (forwardedRef) {

            this.currencyMaskMerged = {
                ...DEFAULT_CURRENCY_MASK,
                ...mask
            };
            var localMask = createNumberMask(this.currencyMaskMerged);

            let instance = forwardedRef.current;

            instance.setNewMask(localMask, callback);
        }
    }

    checkIfIsValid = (value: any) => {
        let decimalRegexp = new RegExp(`,\\d{${this.currencyMaskMerged.decimalLimit}}$`);

        if (this.currencyMaskMerged.requireDecimal) 
            return decimalRegexp.test(value);

        value = Util.getDecimalNumber(value);

        if (this.props.min && value < this.props.min){
            this.messageInvalid='o valor não pode ser inferior a ' + this.props.min;
            return false
        }
        if (this.props.max && value > this.props.max){
            this.messageInvalid = 'o valor não pode ser superior a ' + this.props.max;
            return false
        }

        return true;
    }

    render() {
        const { 
            props, 
            onBlur, 
            currencyMask,
            onDefineMsgInvalid,
            messageInvalid
        } = this,
        {
            forwardedRef, 
        } = props;

        return (
            <BaseInput
                {...props}
                mask={this.currencyMask}
                onBlur={onBlur}
                isCurrency
                ref={forwardedRef}
                feedback={{ invalid: messageInvalid, onDefineMsgInvalid: onDefineMsgInvalid}}
                inheritanceComponent = {this}
            />
        );
    }
}

CurrencyInput.defaultProps = {    
   label: 'Valor',
    feedback: {
        invalid: 'Informe um valor válido',
    }
}

export default React.forwardRef<BaseInput, finalProps>((props, ref) => {
    return <CurrencyInput {...props } forwardedRef={ref} />
});