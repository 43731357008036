import React, { RefObject } from "react";
import { Col } from "react-bootstrap";
import { faCalendarAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import DatePicker, {
  registerLocale,
  ReactDatePickerProps
} from "react-datepicker";
import "./style.scss";

import ptBR from "date-fns/locale/pt-BR";
import BaseInput from "../input";
import { ICommonInputProps } from "../base";
import Util from "../../util";
import { isDate } from "date-fns";
registerLocale("ptBR", ptBR);

export const DATE_MASK = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

export interface IDateInputProps
  extends  Omit<Partial<ICommonInputProps>, 'onBlur' | 'step' 
  | 'prepend' | 'append' | 'max' | 'min' | 'step' | 'mask' | 'onSetValue' | 'onChange' | 'onReset'
  | 'feedback'>,
    Partial<Omit<ReactDatePickerProps, "name" | "onBlur" | "onChange" >> {
  col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  callbackChange?(date: Date | null): void | undefined;
  forwardedRef?: any;
  showYearPicker?: boolean;
}

interface finalProps extends Omit<Partial<IDateInputProps>, 'forwardedRef'>{}

class DateInput extends React.Component<IDateInputProps> {
  static defaultProps: IDateInputProps;

  state = {
    date: null,
    messageInvalid: 'Data Invalida'
  };

  handleChange = (date: Date | null) => {
    let isValidRanged = this.checkIfRangeIsValid(date);

    let instance = this.props.forwardedRef;

    if (instance && instance.current) {
      instance.current.setValue(date ? Util.dateISOToDate(date) : date, date);
      instance.current.setIsInvalid(!isValidRanged);
    }

    this.setState(
      () => ({ date: date}),
      () => {
        this.props.callbackChange && this.props.callbackChange(date);
      }
    );
  };

  handleTextChange = (date: string | null) => {
    
    if(date && date.length===10){
      let aDate = date.split('/');
      let sDate = aDate[2] + '-' + aDate[1] + '-' + aDate[0] + ' 00:00:00.0000';
      try{
        let iDate = new Date(sDate);
        if(!isNaN(iDate.getTime())){
          this.handleChange(iDate);
        }
      }
      catch(ex){}
    }
  };

  onBlur = () => {
    let date = this.state.date;

    if (!date) return null;

    let isValidRanged = this.checkIfRangeIsValid(date),
    instance = this.props.forwardedRef;

    if (instance && instance.current) {
        instance.current.setValue(date ? Util.dateISOToDate(date) : date, date);
        instance.current.setIsInvalid(!isValidRanged);
    }

    this.setState(
      { date: date},
      () => {
        this.props.callbackChange && this.props.callbackChange(date);
      }
    );
  };

  CustomValidationIsInvalid = ():boolean => {
    let date = this.state.date;

    if (!date) return false;

    if (this.checkIfRangeIsValid(date)){
      if (this.props.onCustomValidationIsInvalid) {
        return this.props.onCustomValidationIsInvalid();
      }
    }
    else return true;
  }

  callBackSetValue = (value: any) => {
    
    if(value && value.length===10){
      let aDate = value.split('/');
      let sDate = aDate[2] + '-' + aDate[1] + '-' + aDate[0] + ' 00:00:00.0000';
      let iDate = new Date(sDate);
      if(!isNaN(iDate.getTime())){
        
        let isValidRanged = this.checkIfRangeIsValid(iDate),
        instance = this.props.forwardedRef;
    
        if (instance && instance.current) {
            instance.current.setIsInvalid(!isValidRanged);
        }

        this.setState(
          () => ({ date: iDate}),
          () => {
            this.props.callbackChange && this.props.callbackChange(iDate);
          }
        );
      }
    }
  };

  checkIfRangeIsValid = (date: Date | null) => {
    const { minDate, maxDate } = this.props;
    if (date) {

      date.setHours(0,0,0,0);

      if (minDate && isDate(minDate)){
        minDate?.setHours(0, 0, 0, 0);
      }
      if (maxDate && isDate(maxDate)){
        maxDate?.setHours(0, 0, 0, 0);
      }

      if (minDate && (date < minDate)) {
        this.setState({messageInvalid:'A Data não pode ser menor que ' + Util.dateISOToDate(minDate) })
        return false;
      }

      if (maxDate && (date > maxDate)){ 
        this.setState({messageInvalid:'A Data não pode ser maior que ' + Util.dateISOToDate(maxDate) })
        return false;
      }
    }

    return true;
  };

  onDefineMsgInvalid = () => this.state.messageInvalid;

  render() {
    const { col, forwardedRef, label, name, id } = this.props;

    return (
      <Col xs={col!.xs} sm={col!.sm} md={col!.md} lg={col!.lg} xl={col!.xl}>
        <DatePicker
          {...this.props}
          selected={this.state.date}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          customInput={
            <CustomDateInput
              {...this.props}
              id={id}
              name={name}
              label={label}
              handleChange={this.handleChange}
              innerRef={forwardedRef}
              onBlur={this.onBlur}
              handleTextChange={this.handleTextChange}
              onDefineMsgInvalid={this.onDefineMsgInvalid}
              callBackSetValue={this.callBackSetValue}
              onCustomValidationIsInvalid={this.CustomValidationIsInvalid}
            />
          }
        />
      </Col>
    );
  }
}

DateInput.defaultProps = {
  label: "Data",
  name: "date",
  id: "datepicker",
  col: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6
  }
};

interface ICustomDateInput extends ICommonInputProps {
  handleChange(date: Date | null | string): void | undefined;
  innerRef?: RefObject<any>;
  handleTextChange(date: string | null):void | undefined;
  onDefineMsgInvalid():string;
  callBackSetValue?(value:any):void;
}

export class CustomDateInput extends React.Component<ICustomDateInput> {
  render() {
    const { onClick, handleChange, innerRef, handleTextChange, onDefineMsgInvalid, callBackSetValue } = this.props;

    return (
      <BaseInput
        {...this.props}
        autoComplete="off"
        prepend={{
          onClick: onClick,
          buttonProps: {
            variant: "primary"
          },
          icon: faCalendarAlt,
          hideWhenRead: true
        }}
        append={{
          onClick: () => handleChange(null),
          buttonProps: {
            variant: "secondary"
          },
          icon: faTrash,
          hideWhenRead: true
        }}
        mask={DATE_MASK}
        ref={innerRef}
        feedback={{
          invalid: 'data Invalida',
          onDefineMsgInvalid: onDefineMsgInvalid
        }}
        isDate
        onReset={() => handleChange(null)}
        onChange={(event)=> handleTextChange(event.target.value)}
        onSetValue={callBackSetValue}
      />
    );
  }
}

export default React.forwardRef<BaseInput,finalProps>((props, ref) => {
  return <DateInput {...props} forwardedRef={ref} />;
});
