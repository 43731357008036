import React, { Component } from 'react';
import Loading from '../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { Container, Log, Divider, Card, DateInfo, Feedback } from './styles';
import BaseFieldSet from '../fieldset';
import { Col, Row } from 'react-bootstrap';
import BaseInput from '../input/input';
import Axios from 'axios'

export interface ILogSQLProps {
    id: number,
    endpoint: string,
}

interface ILogSQLState {
    loading: boolean,
    data: Array<any>,
    filteredData: Array<any>,
    filterUser: Array<any>,
    filterField: Array<any>,
    isFilter: boolean,
    success: boolean,
    error?: {
        code: number,
        message: string
    }
}

export default class LogSQL extends Component<ILogSQLProps, ILogSQLState>
{
    state: ILogSQLState = {
        loading: true,
        data: [],
        filteredData: [],
        filterUser: [],
        filterField: [],
        isFilter: false,
        success: false
    }

    private user = React.createRef<BaseInput>();
    private field = React.createRef<BaseInput>();

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        const {
            id,
            endpoint
        } = this.props;

        const result = await Axios.get(`${endpoint}/${id}`);

        const { success, error } = result.data;

        if (success) {
            this.setState({
                data: result.data.data.register.descriptions,
                success: success
            })
        } else {
            this.setState({
                error: error
            })
        }

        this.setState({
            loading: false,
        })

    }

    onHandleChangeUser = (e: { target: { value: string; }; }) => {
        let value = e.target.value.toLowerCase();
        setTimeout(() => {

            if (value === "" && (this.field.current.state.value === "" && this.user.current.state.value === "")) {
                this.setState({ filteredData: [], isFilter: false })
            } else if (value === "" && this.field.current.state.value !== "") {
                this.setState({ filteredData: this.state.filterField })
            } else {

                let filter = this.state.isFilter && this.field.current.state.value !== "" ? this.state.filteredData.filter(i => i.userName.toLowerCase().includes(value)) : this.state.data.filter(i => i.userName.toLowerCase().includes(value))
                this.setState({ filteredData: filter, isFilter: true, filterUser: filter })
            }
        }, 100);

    }

    onHandleChangeFields = (e: { target: { value: string; }; }) => {
        let value = e.target.value.toLowerCase();
        setTimeout(() => {
            if (value === "" && (this.field.current.state.value === "" && this.user.current.state.value === "")) {
                this.setState({ filteredData: [], isFilter: false })
            } else if (value === "" && this.user.current.state.value !== "") {
                this.setState({ filteredData: this.state.filterUser })
            } else {
                let filter = this.state.isFilter && this.user.current.state.value !== "" ? this.state.filteredData.reduce((acc, current, index) => {

                    var exists = current.items.filter((i: { propertyDescription: string; }) => i.propertyDescription.toLowerCase().includes(value))?.length > 0;

                    if (exists) {
                        acc.push(current);
                        return acc;
                    }
                    return acc;

                }, []) : this.state.data.reduce((acc, current, index) => {

                    var exists = current.items.filter((i: { propertyDescription: string; }) => i.propertyDescription.toLowerCase().includes(value))?.length > 0;

                    if (exists) {
                        acc.push(current);
                        return acc;
                    }
                    return acc;

                }, []);
                this.setState({ filteredData: filter, isFilter: true, filterField: filter })
            }

        }, 100);

    }

    render() {

        while (this.state.loading) {
            return (
                <Loading hidden={!this.state.loading} />
            )
        }

        return (
            <><BaseFieldSet legend="Filtros">
                <Row>
                    <Col sm="6" md="6" xs="6" xl="6">
                        <BaseInput
                            id='sqlLogBaseInputUser'
                            label='Usuário'
                            name='user'
                            informationMessage='Utilizado para filtrar os logs pelo nome do usuário.'
                            ref={this.user}
                            onChange={(e) => this.onHandleChangeUser(e)}
                        />
                    </Col>

                    <Col>
                        <BaseInput
                            id='sqlLogBaseInputAll'
                            label='Campos'
                            name='fields'
                            informationMessage='Utilizado para filtrar os logs pelo nome do campo.'
                            ref={this.field}
                            onChange={(e) => this.onHandleChangeFields(e)}
                        />
                    </Col>
                </Row>

            </BaseFieldSet>
                <BaseFieldSet legend="Histórico de Modificações">
                    <Row>
                        <Container>
                            {this.state.data.length >= 1 && this.state.success && !this.state.isFilter
                                ? this.state.data.map((Logs, index) => (
                                    <Log isPair={((index + 1) % 2) === 0} key={Logs.id}>
                                        <Divider />

                                        <Card isPair={((index + 1) % 2) === 0}>
                                            <div style={{ textAlign: 'center' }}>
                                                <strong>Usuário: </strong> {Logs.userName}
                                            </div>
                                            <div style={{ borderBottom: 'solid 1px', borderBottomColor: '#d3d3d3', marginLeft: '15%', marginRight: '15%' }}>
                                            </div>

                                            <div key={Logs.id} style={{ marginTop: '3%' }}>
                                                <strong>Ação: </strong> {Logs.descriptive}
                                            </div>

                                            {Logs.items?.length >= 1 ? Logs.items?.map((item: any) => {
                                                switch (item.dataType) {
                                                    case 'Date':
                                                        return (
                                                            <div key={item.id} style={{ paddingLeft: '3%' }}>
                                                                <strong>{item.propertyDescription}: </strong>
                                                                <span>
                                                                    {item.beforeChanges?.substring(0, 10)}
                                                                </span>
                                                                {' -> '}
                                                                {item.afterChanges?.substring(0, 10)}
                                                            </div>
                                                        )

                                                    case 'Money':
                                                        return (
                                                            <div key={item.id} style={{ paddingLeft: '3%' }}>
                                                                <strong>{item.propertyDescription}: </strong>
                                                                <span>
                                                                    {`R$: ${item.beforeChanges}`}
                                                                </span>
                                                                {' -> '}
                                                                {`R$: ${item.afterChanges}`}
                                                            </div>
                                                        )

                                                    case 'Percentage':
                                                        return (
                                                            <div key={item.id} style={{ paddingLeft: '3%' }}>
                                                                <strong>{item.propertyDescription}: </strong>
                                                                <span>
                                                                    {`${item.beforeChanges} %`}
                                                                </span>
                                                                {' -> '}
                                                                {`${item.afterChanges} %`}
                                                            </div>
                                                        )
                                                    default:
                                                        return (
                                                            <div key={item.id} style={{ paddingLeft: '3%' }}>
                                                                <strong>{item.propertyDescription}: </strong>
                                                                <span>
                                                                    {item.beforeChanges}
                                                                </span>
                                                                {' -> '}
                                                                {item.afterChanges}

                                                            </div>
                                                        );

                                                };
                                            }) : null}
                                        </Card>

                                        <DateInfo isPair={((index + 1) % 2) === 0}>
                                            <div>
                                                <strong>{format(new Date(Logs.createdAt), 'dd/MM/yyyy')}</strong>
                                            </div>
                                            <div>
                                                <strong>{format(new Date(Logs.createdAt), 'HH:mm:ss')}</strong>
                                            </div>
                                        </DateInfo>
                                    </Log>
                                )) : this.state.filteredData.length >= 1 && this.state.isFilter
                                    ? this.state.filteredData.map((Logs, index) => (
                                        <Log isPair={((index + 1) % 2) === 0} key={Logs.id}>
                                            <Divider />

                                            <Card isPair={((index + 1) % 2) === 0}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <strong>Usuário: </strong> {Logs.userName}
                                                </div>
                                                <div style={{ borderBottom: 'solid 1px', borderBottomColor: '#d3d3d3', marginLeft: '15%', marginRight: '15%' }}>
                                                </div>

                                                <div key={Logs.id} style={{ marginTop: '3%' }}>
                                                    <strong>Ação: </strong> {Logs.descriptive}
                                                </div>

                                                {Logs.items?.length >= 1 ? Logs.items?.map((item: any) => {
                                                    switch (item.dataType) {
                                                        case 'Date':
                                                            return (
                                                                <div key={item.id} style={{ paddingLeft: '3%' }}>
                                                                    <strong>{item.propertyDescription}: </strong>
                                                                    <span>
                                                                        {item.beforeChanges?.substring(0, 10)}
                                                                    </span>
                                                                    {' -> '}
                                                                    {item.afterChanges?.substring(0, 10)}
                                                                </div>
                                                            )

                                                        case 'Money':
                                                            return (
                                                                <div key={item.id} style={{ paddingLeft: '3%' }}>
                                                                    <strong>{item.propertyDescription}: </strong>
                                                                    <span>
                                                                        {`R$: ${item.beforeChanges}`}
                                                                    </span>
                                                                    {' -> '}
                                                                    {`R$: ${item.afterChanges}`}
                                                                </div>
                                                            )

                                                        case 'Percentage':
                                                            return (
                                                                <div key={item.id} style={{ paddingLeft: '3%' }}>
                                                                    <strong>{item.propertyDescription}: </strong>
                                                                    <span>
                                                                        {`${item.beforeChanges} %`}
                                                                    </span>
                                                                    {' -> '}
                                                                    {`${item.afterChanges} %`}
                                                                </div>
                                                            )
                                                        default:
                                                            return (
                                                                <div key={item.id} style={{ paddingLeft: '3%' }}>
                                                                    <strong>{item.propertyDescription}: </strong>
                                                                    <span>
                                                                        {item.beforeChanges}
                                                                    </span>
                                                                    {' -> '}
                                                                    {item.afterChanges}

                                                                </div>
                                                            );

                                                    };

                                                }) : null}
                                            </Card>

                                            <DateInfo isPair={((index + 1) % 2) === 0}>
                                                <div>
                                                    <strong>{format(new Date(Logs.createdAt), 'dd/MM/yyyy')}</strong>
                                                </div>
                                                <div>
                                                    <strong>{format(new Date(Logs.createdAt), 'HH:mm:ss')}</strong>
                                                </div>
                                            </DateInfo>
                                        </Log>
                                    )) : (
                                        <Feedback>
                                            <strong>
                                                <FontAwesomeIcon className="text-warning" size="lg" icon={faInfo} />  {this.state.filteredData.length === 0 && this.state.isFilter ? "Informação não encontrada" : this.state.error?.message}
                                            </strong>
                                        </Feedback>
                                    )}
                        </Container>
                    </Row>
                </BaseFieldSet></>

        )

    }
}