import React, { ReactNode, Fragment, ReactElement } from 'react'
import { Nav } from 'react-bootstrap'
import Util from '../util'
import { FilterOperator } from '../grid';

/**
 * Propriedades para configuração da navegação por tabs
 */
export type BaseNavTabsProps = {
    /** Tabs de navegação. Serão renderizadas na ordem em que foram passadas */
    navs: {
        /** Identificação únida da tab */
        key: string,

        name: string,

        /** Conteúdo a ser exibido */
        content: ReactNode,
                
        /** Configuração de depêndencia de tab */
        dependentNav?: DependentNav,
        hidden?: boolean,
        disabled?: boolean
    }[],
    /** Tab que será iniciada em modo ativo (selecionado) */
    defaultActiveKey: string,

    /** Método que é chamado quando uma nova tab é selecionada */
    onSelect?(key: string): void,

    /** Configuração de depêndencia de tab que será aplicada caso não exista uma dependêcia espeficia configurada */
    dependentNav?: DependentNav
}

type DependentNav = {
    key: string,
    /** nome da propriedade pai que irá passar o valor para a tab dependente */
    parentProperty?: string,

    /** Nome da propriedade filha que irá receber o valor da propriedade pai.
     * Se não informado, o valor da propriedade parent é assumido como default
     */
    childProperty?: string,

    /** Nome da propriedade que irá exibir no filtro.
     * Se não informado, o valor da propriedade parent é assumido como default */
    displayProperty?: string
}

class BaseNavTabs extends React.Component<BaseNavTabsProps, {[k: string]: any}> {

    state = {
        selectedNav: this.props.defaultActiveKey
    } as {[k: string]: any};

    onSelectNavTab = (selectedNav: string) => {
        const { onSelect } = this.props;
        
        this.setState({
            selectedNav
        }, () => {
            if (onSelect) onSelect(selectedNav);
        });
    }

    onSelectData = (data: any) => this.setState(state => (
        { [state.selectedNav]: data }
    ), () => this.checkHasChildDependency());

    displayOrNot = (key: string) : string => {
        return this.state.selectedNav === key ? 'block' : 'none';
    }

    getDynamicProps = (dependentNav: DependentNav) => {
        let dynamicProps: any = {},
            data = this.state[dependentNav.key];
            data = data ? data[0] : data;

            dependentNav.childProperty = dependentNav.childProperty || dependentNav.parentProperty;
            dependentNav.displayProperty = dependentNav.displayProperty || dependentNav.parentProperty;

        if (dependentNav.parentProperty && data) {
            let filterValue = data[dependentNav.parentProperty],
                filterDisplay = data[dependentNav.displayProperty!];

            dynamicProps = {
                filter: [{
                    operator: FilterOperator.Equals,
                    property: dependentNav.childProperty,
                    value: filterValue,
                    display: Util.formatRawValue(filterValue, filterDisplay, false),
                    closeable: false
                }],
                
                dependentProperty: {
                    name: dependentNav.childProperty,
                    value: filterValue
                }
            }
        }

        return dynamicProps;
    }

    checkNavDisabled = (disabled?: boolean, dependentNav?: DependentNav) => {
        if (disabled)
            return disabled;

        dependentNav = dependentNav ? dependentNav : this.props.dependentNav;

        if (dependentNav) {
            const { key: navKey } = dependentNav;

            if (this.state[navKey] === undefined || this.state[navKey].length !== 1)
                return true;
        }
        
        return false;
    }

    checkHasChildDependency = () => {
        this.props.navs.forEach(nav => {
            if (nav.dependentNav && (
                this.state[nav.dependentNav.key] === undefined || this.state[nav.dependentNav.key].length !== 1
            ))
                this.setState({
                    [nav.key]: []
                });
        })
    }

    render() {
        const {
            props,
            state,
            onSelectNavTab,
            getDynamicProps,
            displayOrNot,
            onSelectData,
            checkNavDisabled
        } = this,
        { 
            navs, 
            defaultActiveKey
        } = props,
        {
            selectedNav
        } = state;

        return (
            <Fragment>
                <Nav 
                    variant="tabs" 
                    defaultActiveKey={defaultActiveKey} 
                    onSelect={onSelectNavTab}
                >
                    {navs.map((value, i) => (
                        <Nav.Item 
                            key={i}
                            hidden={value.hidden}
                        >
                            <Nav.Link 
                                eventKey={value.key} 
                                disabled={checkNavDisabled(value.disabled, value.dependentNav)}
                            >
                                {value.name}
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
                {
                    navs.map((value, i) => {
                        let dynamicProps = {};

                        if (value.dependentNav)
                            dynamicProps = getDynamicProps(value.dependentNav);
                        else if (props.dependentNav)
                            dynamicProps = getDynamicProps(props.dependentNav);

                        return (
                            <div 
                                key={i} 
                                style={{display: displayOrNot(value.key)}}
                            >
                                {React.cloneElement(
                                    value.content as ReactElement, 
                                    {
                                        load: selectedNav === value.key,
                                        onSelect: onSelectData,
                                        ...dynamicProps
                                    }
                                )}
                            </div>
                        );
                    })
                }
            </Fragment>
        );
    }
}

export default BaseNavTabs;